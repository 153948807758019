import Link from 'next/link'
import { ReactNode } from 'react'

type Props = {
  title: string
  children: ReactNode
  editText?: ReactNode
  // choose one of the following
  editLink?: string
  onEditClick?: () => void
  seeAllText?: ReactNode
  // choose one of the following
  seeAllLink?: string
  onSeeAllClick?: () => void
}

const ProfileSectionWrapper = (props: Props) => {
  const {
    title,
    children,
    editText = 'Edit',
    seeAllText = 'See all >',
    seeAllLink,
    onSeeAllClick,
    editLink,
    onEditClick,
  } = props

  const showBullet = (onEditClick || editLink) && (onSeeAllClick || seeAllLink)
  return (
    <div className="w-full space-y-2">
      <div className="inline-flex w-full items-center justify-between gap-2 lg:gap-4">
        {/* title */}
        <h3 className="text-[20px] font-semibold leading-[1.2] text-kokushoku-black lg:text-[28px]">{title}</h3>

        {/* button */}
        <div className="flex items-center gap-2 pr-2 lg:pr-4">
          {/* edit */}
          <div className="empty:hidden">
            {editLink && (
              <Link
                passHref
                href={editLink}
                className="text-[10px] font-medium uppercase tracking-wider text-primary lg:text-xs"
              >
                {editText}
              </Link>
            )}

            {onEditClick && (
              <a className="text-[10px] font-medium uppercase text-primary lg:text-xs" onClick={onSeeAllClick}>
                {editText}
              </a>
            )}
          </div>
          {showBullet && <i className="size-1 rounded-full  bg-black" />}
          {/* see all */}
          <div className="empty:hidden">
            {seeAllLink && (
              <Link
                passHref
                href={seeAllLink}
                className="whitespace-nowrap text-[10px] font-medium uppercase text-primary lg:text-xs "
              >
                {seeAllText}
              </Link>
            )}

            {onSeeAllClick && (
              <a
                className="cursor-pointer whitespace-nowrap text-[10px] font-medium uppercase text-primary lg:text-xs"
                onClick={onSeeAllClick}
              >
                {seeAllText}
              </a>
            )}
          </div>
        </div>
      </div>
      {children}
    </div>
  )
}

export default ProfileSectionWrapper
