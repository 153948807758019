import { useCallback } from 'react'
import { apiClient } from 'utils/apiClient'
import { NewBackEndApiResponseData } from 'types/ApiResponseData'
import useSWR from 'swr'
import { LinkButtonType } from 'shared/types/LinkInBio'

type ResponseType = NewBackEndApiResponseData<{
  list: Array<LinkButtonType>
  isVisible: boolean
}>

// fetch user's public custom cards for link-in-bio page
const useFetchingUserFeaturedLinks = (username: string) => {
  const swrKey = `/v2/link-in-bio/public/cards?username=${username}`
  const fetcher = useCallback(async (swrKey: string) => {
    const response = await apiClient.get<ResponseType>(swrKey)
    const { data } = response.data
    return data
  }, [])

  return useSWR(swrKey, fetcher)
}

export default useFetchingUserFeaturedLinks
