import { memo } from 'react'
import LinkButton from 'shared/components/link-in-bio/featured-links/LinkButton'
import useFetchingUserFeaturedLinks from 'shared/hooks/data-fetching/useFetchingUserFeaturedLinks'
import ProfileSectionWrapper from './ProfileSectionWrapper'

type ProfileMomentsCarouselProps = {
  username: string
}

const ProfileFeaturedLinks = (props: ProfileMomentsCarouselProps) => {
  const { username } = props
  const { data } = useFetchingUserFeaturedLinks(username)
  const { list: featuredLinks = [], isVisible } = data || {}
  const shouldShowFeaturedLinks = isVisible && featuredLinks.length > 0

  if (!shouldShowFeaturedLinks) return null // user hides custom cards, or has no custom cards
  return (
    <ProfileSectionWrapper title="Featured Links">
      <div className="flex w-full flex-wrap gap-3">
        {featuredLinks.map((featuredLink, index) => (
          <LinkButton key={index} label={featuredLink.title} link={featuredLink.url} />
        ))}
      </div>
    </ProfileSectionWrapper>
  )
}

export default memo(ProfileFeaturedLinks)
