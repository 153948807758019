import cn from 'classnames'
import React, { useCallback } from 'react'
import { getValidHrefFromLink } from 'utils/common'

type Props = {
  label?: string | null
  link?: string | null
}

const LinkButton = (props: Props) => {
  const { label, link } = props
  const onFeaturedLinkClick = useCallback(() => {
    if (!link) return // do nothing if there is no link
    const validWebsite = getValidHrefFromLink(link)
    validWebsite && window.open(validWebsite, '_blank')
  }, [link])

  if (!label) return null
  return (
    <button
      className={cn(
        'flex items-center gap-1 rounded-[24px] px-4 py-2 text-[10px] font-semibold',
        'line-clamp-1 w-[260px] border border-primary',
        'justify-center text-primary',
        'disabled:opacity-50',
      )}
      disabled={!link}
      onClick={onFeaturedLinkClick}
    >
      {label}
    </button>
  )
}

export default LinkButton
